.menu {
  display: flex;
  align-items: center;
  padding-left: 0;
  list-style: none;

  @include breakpoint-down("md") {
    display: none;
  }

  &__item {
    padding: 0 18px;
  }

  &__link {
    display: block;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;

    @include font(tertiary);
    @include font-size(header-menu);
  }
}
