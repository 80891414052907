.container {
  @include make-container();

  &--fluid {
    max-width: 100%;
  }
}

.row {
  @include make-row();

  &.row--no-gutters {
    margin-left: 0;
    margin-right: 0;

    & > .col {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .col {
    @include make-col();

    &-auto {
      @include make-col();
      @include make-col-auto();
    }
  }

  @for $i from 1 through $grid-columns {
    .col-#{$i} {
      @include make-col-size($i);
    }

    .offset-#{$i} {
      @include make-col-offset($i);
    }
  }

  @each $infix, $breakpoint in $grid-breakpoints {
    @media screen and (min-width: $breakpoint) {
      .col-#{$infix}-auto {
        @include make-col-auto();
      }

      @for $i from 1 through $grid-columns {
        .col-#{$infix}-#{$i} {
          @include make-col-size($i);
        }
      }

      @for $i from 0 through ($grid-columns - 1) {
        .offset-#{$infix}-#{$i} {
          @include make-col-offset($i);
        }
      }
    }
  }
}
