@mixin make-container() {
	display: block;
	width: 100%;
	max-width: $container-max-width;
	padding-right: 0.5 * $grid-gutter-width;
	padding-left: 0.5 * $grid-gutter-width;
	margin-right: auto;
	margin-left: auto;
}

@mixin make-row() {
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.5 * $grid-gutter-width;
	margin-right: -0.5 * $grid-gutter-width;
}

@mixin make-col() {
	flex-shrink: 0;
  width: 100%;
  max-width: 100%;
	box-sizing: border-box;
	padding-left: 0.5 * $grid-gutter-width;
	padding-right: 0.5 * $grid-gutter-width;
}

@mixin make-col-size($column-count) {
	$column-width: 100% / $grid-columns * $column-count;
	flex: 0 0 $column-width;
	width: $column-width;
}

@mixin make-col-auto() {
	flex: 0 0 auto;
	width: auto;
}

@mixin make-col-offset($column-count) {
	$column-offset: 100% / $grid-columns * $column-count;
	margin-left: $column-offset;
}
