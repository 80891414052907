.section {
  &__close {
    position: fixed;
    z-index: 3;
    right: 40px;
    top: 40px;

    @include breakpoint-down("lg") {
      display: none;
    }

    button {
      all: unset;
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;

      span {
        width: 30px;
        height: 2px;
        background-color: color(text);
        position: absolute;
        transition: background-color 0.325s ease;
        pointer-events: none;

        &:first-child {
          transform: rotate(-45deg);
        }

        &:last-child {
          transform: rotate(45deg);
        }
      }

      &:hover {
        span {
          background-color: color(secondary);
        }
      }
    }
  }

  &__content {
    max-width: 720px;
    margin: 0 auto;
    padding: 64px 30px;

    @include breakpoint-up("lg") {
      padding: 120px 48px;
    }
  }

  &__img {
    max-width: 100%;
    height: auto;

    &--cover {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-weight: normal;
    color: color(text);
    margin-bottom: 20px;

    b {
      font-weight: 700;
    }
  }

  &__col {
    @each $key, $val in $colors {
      &--bg-#{$key} {
        background-color: color($key);
      }
    }
  }

  &__nav {
    margin-top: 40px;

    @include breakpoint-down("lg") {
      display: none;
    }

    @include breakpoint-up("lg") {
      margin-top: 120px;
    }

    &-link {
      color: color(secondary);
      text-transform: uppercase;
      transition: color 0.325s ease;
      margin-right: 16px;

      @include font(tertiary);

      &:hover {
        color: color(text);
      }
    }
  }

  &__socials {
    margin-top: 40px;
    display: flex;
    align-items: center;
  }

  &[hidden] {
    display: flex !important;

    @include breakpoint-up("lg") {
      display: none !important;
    }
  }

  &--cover {
    padding: 64px 0;

    @include breakpoint-up("lg") {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: white !important;
      display: flex;
      align-items: center;

      & > * {
        width: 100%;
      }
    }

    .section__scroll {
      @include breakpoint-up("lg") {
        height: 90vh;
        padding: 120px 0;
        overflow-y: scroll;
      }
    }
  }

  @each $key, $val in $colors {
    &--bg-#{$key} {
      background-color: color($key);
    }
  }
}
