.social-link {
  display: block;
  margin-right: 24px;

  img {
    width: 24px;
    height: 24px;
    object-fit: container;
    object-position: center;
  }
}
