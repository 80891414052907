@each $name, $font in $fonts {
	@each $style in map-get($font, styles) {
		$src: null;

		@each $format in map-get($font, formats) {
			$src: append($src, url("../fonts/#{map-get($style, filename)}.#{$format}") format($format), comma);
		}

		@font-face {
			font-family: map-get($font, name);
			font-style: map-get($style, font-style);
			font-weight: map-get($style, font-weight);
			src: $src;
		}
	}
}
