// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin breakpoint-up($key) {
  $min: bp($key);
  @if $min {
    @media screen and (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin breakpoint-down($key) {
  $max: bp($key);
  @if $max {
    @media screen and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
