.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;

  @include breakpoint-up("lg") {
    padding: 120px 0;
  }

  &__icon {
    object-fit: contain;
    width: 148px;
    height: 148px;
  }

  &__title {
    text-align: center;
    text-transform: lowercase;
    margin-top: 56px;

    @include font-size(sidebar-title);
    @include font(secondary);
  }

  &__entries {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-top: 48px;
    flex-direction: column;

    @include breakpoint-up("xl") {
      flex-direction: row;
      margin-top: 80px;
    }
  }

  &__entry {
    padding: 0 30px;
  }

  &__link,
  &__entry {
    color: color(text);
  }
}
