.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  &__content {
    width: 100%;
    padding: 80px 30px;
    background-color: color(primary);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    flex-direction: column;

    @include breakpoint-up("md") {
      height: 100vh;
      padding: 0 30px;
    }
  }

  &__title {
    color: #fff;
    margin: 24px 0;
    line-height: 1.15em;

    @include font(secondary);
    @include font-size(header-title);
  }

  &__description {
    color: #fff;
    margin-bottom: 48px;
    @include font(tertiary);
    @include font-size(header-description);
  }

  &__arrow {
    margin-bottom: 24px;
  }
}
