@mixin font($font, $font-weight: 400) {
	@if map-has-key($fonts, $font) {
		$font-map: map-get($fonts, $font);

		@if map-has-key($font-map, name) {
			$font-family: map-get($font-map, name);

			@each $fallback in map-get($font-map, fallbacks) {
				$font-family: append($font-family, $fallback, comma);
			}

			font-family: $font-family;
			font-weight: $font-weight;
		}
	}
}
