.uc-aspect-ratio {
	position: relative;
	overflow: hidden;

	img,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@each $name, $aspect-ratio in $aspect-ratios {
		$width: map-get($aspect-ratio, width);
		$height: map-get($aspect-ratio, height);

		&--#{$width}-#{$height} {
			@include make-aspect-ratio($width, $height);
		}
	}
}
