body,
.uc-text {
  color: color(text);
  line-height: 1.65em;

  @include font(primary);
  @include font-size(text);
}

p > a,
li > a {
  color: color(secondary);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

p + p {
  margin-top: 1.25em;
}

ul:not([class]) {
  padding-left: 0;
}
