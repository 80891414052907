.timeline {
  margin: 48px 0;

  &__entry {
    position: relative;
    border-left: 2px dashed color(secondary);
    padding-left: 24px;
    padding-top: 24px;

    @include breakpoint-up("sm") {
      margin-left: -24px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: 2px solid color(secondary);
      background-color: #fff;
      left: -11px;
      top: 30px;
      display: block;
    }

    &:first-child {
      padding-top: 12px;

      &::before {
        top: 18px;
      }

      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 18px;
        background-color: #fff;
        top: 0;
        left: -15px;
        display: block;
      }
    }

    &:last-child {
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 100%;
        background-color: #fff;
        top: 50px;
        left: -15px;
        display: block;
      }
    }
  }
}
