h1,
.h1 {
  @include font(primary);
  @include font-size(h1);
}

h2,
.h2 {
  margin-bottom: 12px;
  @include font(primary, 700);
  @include font-size(h2);
}

p + h2,
p + .h2 {
  margin-top: 32px;
}

h3,
.h3 {
  margin-bottom: 8px;
  @include font(primary, 700);
  @include font-size(h3);
}

p + h3,
p + .h3 {
  margin-top: 24px;
}

h4,
.h4 {
  margin-bottom: 4px;
  @include font(tertiary, 700);
  @include font-size(h4);
}
