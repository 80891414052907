$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  2xl: 1400px,
);
$grid-columns: 12;
$grid-gutter-width: 60px;
$grid-row-columns: 6;

$container-max-width: 720px;
