.menu-opener {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  background-color: color(primary);

  @include breakpoint-down("md") {
    display: none;
  }

  &__burger {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    span {
      margin: 2px;
      width: 24px;
      height: 2px;
      background-color: #fff;
    }
  }

  &__label {
    color: #fff;
    text-transform: uppercase;
    @include font(tertiary);
  }
}
